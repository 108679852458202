@use "src/styles/mixins/ff" as *;
@import "/src/styles/mixin";

.wrap {
  max-width: 235px;
  position: relative;
  padding: 12px;
  border-radius: 20px;
  //border-radius: var(--border-radius-small);
  background-color: var(--white-color);
  border: 1px solid var(--grey-lines-color);
  transition: all 0.2s ease;

  &:hover {
    border-color: transparent;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
  }

  .link {
    display: flex;
    flex-direction: column;

    .image_wrap {
      position: relative;
      display: flex;
      margin-bottom: 12px;
      padding: 10px;
      border-radius: var(--border-radius-small);

      .img {
        height: 100%;
        object-fit: contain;
        aspect-ratio: 1/1;
        mix-blend-mode: multiply;
        user-select: none;
        z-index: 1;
      }

      .image_loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        aspect-ratio: 1 / 1;
        z-index: 2;
      }

      &.notAvailable {
        opacity: 0.5;
      }
    }
  }

  &.inCart {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: auto;
    background-color: var(--primary-color);
    border: none;
    transition: all 0.25s ease-in-out;
    padding: 20px;
    //min-height: 384px;
    border-radius: 20px;
    cursor: pointer;

    .link {
      width: 100%;
      max-width: 100%;
      margin-bottom: 50px;

      .image_wrap {
        max-width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        background-color: var(--white-color);
        margin-bottom: 0;
      }

      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
        max-width: 100%;
      }
    }

    .cart_value {
      display: flex;
      flex-direction: column;
      gap: 10px;
      max-width: 210px;

      .price {
        @include ff(24px, 600, 28px);

        & > span {
          white-space: nowrap;
        }

        @media screen and (max-width: 767px) {
          @include ff(20px, 600, 24px);
        }

        @media screen and (max-width: 374px) {
          @include ff(18px, 600, 24px);
        }
      }

      .info {
        display: flex;
        flex-direction: row;
      }
    }

    &:hover {
      background-color: var(--hover-primary-color);
    }

    @media screen and (max-width: 767px) {
      padding: 10px;
      border-radius: var(--border-radius-normal);
      .image_wrap {
        border-radius: 12px;
      }
    }

    @media screen and (max-width: 440px) {
      padding-bottom: 30px;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 767.98px) {
    padding: 0 0 12px 0;
    border: none;
    &:hover {
      box-shadow: none;
    }
  }
}

.name {
  @include font14;
  height: 50px;
  margin-bottom: 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  color: var(--secondary-color);
  overflow: hidden;
}

.reviews {
  display: flex;
  gap: 20px;
  margin-bottom: 8px;
  align-items: center;
  height: 20px;

  &Numbers {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    line-height: 20px;
  }

  &Rating {
    margin-right: 8px;
  }

  &Count {
    color: var(--grey-third);
    padding-left: 8px;
    border-left: 1px solid var(--grey-secondary);
  }

  @media screen and (max-width: 768px) {
    gap: 16px;
  }
}

.bottom {
  // width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  min-height: 56px;

  @media screen and (max-width: 375px) {
    // min-height: 32px;
    // flex-direction: column;
    justify-content: space-between;
    button {
      min-width: 48px;
      width: 100%;
    }
  }
}

.comission {
  flex-direction: column;
}

.comissionImg {
  height: 100% !important;
  object-fit: contain !important;
  aspect-ratio: 1/1 !important;
  background-color: var(--white-color);
}

.showButton {
  @include contentButton;
  padding: 12px 18px;
}
